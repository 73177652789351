<template>
  <loader-overlay :is-loading="isLoading" />
  <div id="adyen-payment-container"></div>
  <div id="adyen-payment-action-modal"
        class="modal adyen-payment-modal"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
        tabindex="-1"
        role="dialog"
        data-adyen-payment-action-modal
  >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-body">
                  <div class="adyen-payment-action-container" data-adyen-payment-action-container>
                      <div class="adyen-payment-container loader" role="status">
                          <span class="sr-only">{{ "adyen.loading"|trans }}</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import bsModal from "bootstrap/js/src/modal";
import LoaderOverlay from "../../utils/loader-overlay";
import AdyenPaymentMethod from "./adyen/adyen-payment-method";
import adyenConfiguration from "./adyen/config";
//import { AdyenCheckout, Card } from '@adyen/adyen-web';
//import '@adyen/adyen-web/styles/adyen.css';
//import AdyenPaymentMethod from "./adyen/adyen-payment-method";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

let intialized = false;
export default {
  name: "payment-method-adyen",
  components: { LoaderOverlay },
  emits: [ 'handle-payment' ],

  props: {
    paymentMethod: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      checkout: null,
      confirmFormSubmit: null,
      orderId: null,
    }
  },

  created() {
    this.validationHandler = null;
    this.confirmFormSubmit = document.querySelector('#btn-contract-submit');
  },

  methods: {
    initPaymentMethod() {
      this.isLoading = true;

      const adyenConfirmButton = document.querySelector('#adyen-confirm-button');
      if (adyenConfirmButton) {
        adyenConfirmButton.remove();
      }
      if (this.confirmFormSubmit) {
        this.confirmFormSubmit.classList.remove('d-none');
      }

      this._createAdyenPayment((payment) => {
        this.isLoading = false;
      });
    },

    validate(callback) {
      this.validationHandler = callback;
      this.paymentMethodInstance.submit()
    },

    handlePayment(data) {

    },

    _createAdyenPayment(callback) {
      easyLizeApp.apiClient.createAdyenPayment(this.paymentMethod.id, (payment) => {
        this._createPaymentMethod(payment, callback);
      });
    },

    async _createPaymentMethod(payment, callback) {
      const paymentMethodsResponse = JSON.parse(payment.paymentMethod.paymentMethodsResponse);
      const selectedPaymentMethodKey = this._getSelectedPaymentMethodKey(payment.paymentMethod);
      const showPayButton = selectedPaymentMethodKey in adyenConfiguration.componentsWithPayButton;
      if(showPayButton && this.confirmFormSubmit) {
        this.confirmFormSubmit.classList.add('d-none');
      }
      this.adyenCheckout = await new AdyenCheckout({
        locale: payment.config.locale,
        clientKey: payment.config.clientKey,
        environment: payment.config.environment,
        showPayButton: showPayButton,
        hasHolderName: true,
        paymentMethodsResponse: paymentMethodsResponse,
        countryCode: payment.shippingAddress.country,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            clickToPayConfiguration: {
              merchantDisplayName: payment.config.merchantAccount,
              shopperEmail: payment.shopper.shopperEmail
            }
          }
        },
        onAdditionalDetails: (state) => {
          console.log('onAdditionalDetails', { state, orderId: this.orderId, returnUrl: this.returnUrl });
          easyLizeApp.apiClient.postAdyenPaymentDetails(payment.paymentMethod.paymentDetailsUrl, this.orderId, JSON.stringify(state.data || {}), (response) => {
            console.log('postAdyenPaymentDetails', { response, returnUrl: this.returnUrl });
            location.href = this.returnUrl;
          });
        },
        onPaymentCompleted: (result, component) => {
          console.log('onPaymentCompleted', { result, component });
          location.href = this.returnUrl;
        },
        onError: (error, component) => {
          console.error({ error, component });
        },
      });
    
      const paymentMethodConfigs = paymentMethodsResponse.paymentMethods.filter(function (paymentMethod) {
        return paymentMethod['type'] === selectedPaymentMethodKey;
      });
      this.paymentMethodInstance = this.adyenCheckout.create(selectedPaymentMethodKey, Object.assign({}, paymentMethodConfigs[0], {
        data: {
          personalDetails: payment.shopper,
          billingAddress: payment.billingAddress,
          deliveryAddress: payment.shippingAddress
        },
        onSubmit: function (state, component) {
          console.log('onSubmit', { state, component });
          this._triggerValidationCallback(state, (response) => {
            this.orderId = response.orderId;
            this.returnUrl = response.redirect;
            console.log('onSubmit', { state, response, orderId: this.orderId, returnUrl: this.returnUrl });
            easyLizeApp.apiClient.getAdyenPaymentStatus(payment.paymentMethod.paymentStatusUrl, response.orderId, this._handleAdyenPaymentStatus);
          });
        }.bind(this)
      })).mount('#payment-method-form-' + this.paymentMethod.id);
      //const adyen = new AdyenPaymentMethod(payment, '#payment-method-form-' + this.paymentMethod.id);
      callback(payment);
    },

    _getSelectedPaymentMethodKey(adyenPaymentMethod) {
      return Object.keys(
        adyenConfiguration.paymentMethodTypeHandlers).find(
          key => adyenConfiguration.paymentMethodTypeHandlers[key] ===
            adyenPaymentMethod.selectedPaymentMethodHandler);
    },

    _triggerValidationCallback(state, startPayment) {
      if (this.validationHandler) {
        this.validationHandler({
          stateData: JSON.stringify(state.data || {}),
        }, startPayment);
      } else {
        this.$emit('handle-payment', state.data || {}, startPayment);
      }
    },

    _handleAdyenPaymentStatus(paymentResponse) {
      try {
        console.log('paymentResponse', paymentResponse);
        if (paymentResponse.isFinal || paymentResponse.action.type === 'voucher') {
          location.href = this.returnUrl;
        }
        if (!!paymentResponse.action) {
          const actionModalConfiguration = {};
          if (paymentResponse.action.type === 'threeDS2') {
            actionModalConfiguration.challengeWindowSize = '05';
          }

          const modalActionTypes = ['threeDS2', 'qrCode']
          if (modalActionTypes.includes(paymentResponse.action.type)) {
            const modalElement = document.getElementById('adyen-payment-action-modal');
            var adyenPaymentModal = new bsModal(modalElement, {
              keyboard: false,
              backdrop: 'static'
            });
            modalElement.addEventListener('show.bs.modal', (event) => {
              console.log('show.bs.modal', event);
              this.adyenCheckout
                .createFromAction(paymentResponse.action, actionModalConfiguration)
                .mount('[data-adyen-payment-action-container]');
            });
            adyenPaymentModal.show();
          } else {
            this.adyenCheckout
              .createFromAction(paymentResponse.action, actionModalConfiguration)
              .mount('[data-adyen-payment-action-container]');
          }
        }
      } catch (e) {
        console.error(e);
        easyLizeApp.apiClient.logError('adyen payment error', e);
      }
    }
  }

}
</script>